
import WizardContentView from '@/ui/components/wizards/baseComponents/WizardContentView.vue';
import InfoTooltip from '@/ui/components/components/InfoTooltip.vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter, Mutation, State } from 'vuex-class';
import WizardComponent from '@/ui/components/wizards/baseComponents/WizardComponent';
import { IProject } from '@/types/project.types';
import { NavigationDirection, WizardPath } from '@/types/wizards/installationWizard.types';
import { IInstallationWizardState } from '@/store/modules/installationWizard/types';
import _ from 'lodash';
import { IDevice } from '@/types/devices.types';
import {
  gridMappingEMS,
  gridMappingEnergyView,
} from '@/ui/components/wizards/installationWizard/wizardSettings/defaultMappings';
import ModalWindow from '@/ui/components/components/ModalWindow.vue';

@Component({
  components: {
    ModalWindow,
    InfoTooltip,
    WizardContentView,
  },
})
export default class Questionnaire extends WizardComponent {
  @State('installationWizard') wizardState!: IInstallationWizardState;
  @Action('projects/updateProject') updateProject!: (project: IProject) => void;
  @Getter('projects/project') project!: IProject;
  @Getter('installationWizard/emsDevice') emsDevice!: IDevice;
  @Getter('installationWizard/energyViewDevice') energyViewDevice!: IDevice;
  @Getter('projects/isSolarmax') isSolarmax!: boolean;
  @Mutation('installationWizard/setInverterCount') setInverterCount!: (data: number) => void;
  @Mutation('installationWizard/setProducerOptionsEnabled') setProducerOptionsEnabled!: (data: boolean) => void;
  @Mutation('installationWizard/updateEMSDeviceData') updateEMSDeviceData!: (payload: Partial<IDevice>) => void;
  @Mutation('installationWizard/updateEnergyViewDeviceData') updateEnergyViewDeviceData!: (payload: Partial<IDevice>) => void;
  @Mutation('installationWizard/setEMSSystemCount') setEMSSystemCount!: ({
    system,
    count,
  }: any) => void;
  @Mutation('installationWizard/setEnergyViewSystemCount') setEnergyViewSystemCount!: ({
    system,
    count,
  }: any) => void;

  valid = false;
  isHybrid = false;
  showError = false;
  activeIndex = -1;

  questionnaireItems = [
    {
      label: this.$t('installationWizard.generalSettings.questionnairePage.questionnaireItems.batterySystemNoTenant'),
      value: false,
    },
    {
      label: this.$t('installationWizard.generalSettings.questionnairePage.questionnaireItems.batterySystemWithTenant'),
      value: false,
    },
  ];

  get imageItems(): { path: string; active: boolean }[] {
    const activeItems = this.questionnaireItems.filter(item => item.value);
    return [
      {
        path: require('../../../../../assets/images/installation wizard/Inverter.png'),
        active: [0, 1].includes(this.activeIndex),
      },
      {
        path: require('../../../../../assets/images/installation wizard/Mieterstrom.png'),
        active: [1, 2, 3].includes(this.activeIndex) || activeItems.length === 2,
      },
      {
        path: require('../../../../../assets/images/installation wizard/Regelung.png'),
        active: [0, 1, 2].includes(this.activeIndex),
      },
      {
        path: require('../../../../../assets/images/installation wizard/StringInverter.png'),
        active: [0, 1, 2, 4].includes(this.activeIndex) || activeItems.length === 2,
      },
    ];
  }

  getGreyedOut(index: number) {
    if (this.activeIndex === -1 || this.activeIndex === index) return false;
    if (index < 3) return true;
    return !(this.activeIndex >= 3 && index >= 3);
  }

  @Watch('questionnaireItems', { deep: true, immediate: true })
  onQuestionnaireItemsChanged() {
    this.validateSelection();
  }

  validateSelection() {
    this.showError = !this.questionnaireItems.some(item => item.value);
  }

  handleCheckboxChange(selectedItem: { value: boolean }, index: number) {
    const activeIndex = this.questionnaireItems.findIndex(item => item.value);
    this.activeIndex = (selectedItem.value) ? index : -1;
    if (!selectedItem.value && activeIndex !== -1) this.activeIndex = activeIndex;
    this.questionnaireItems = this.questionnaireItems.map((item, inx) => {
      if (index < 3) {
        // If any of the first three checkboxes is selected, unselect all other
        return { ...item, value: inx === index };
      } else {
        // If any of the last two checkboxes is selected, unselect the first three
        if (inx < 3) {
          return { ...item, value: false };
        }
        return item;
      }
    });

    this.questionnaireItems[index].value = selectedItem.value;

    if ([2, 3, 4].includes(index)) this.isHybrid = false;
    this.validateSelection();
  }

  defineWizardPath() {
    const activeItems = this.questionnaireItems.filter(item => item.value);
    if (activeItems.length === 2) return WizardPath.TENANT_STRING_INVERTER;
    switch (this.activeIndex) {
      case 0:
        return WizardPath.INSTALLATION;
      case 1:
        return WizardPath.INSTALLATION_TENANT;
      case 2:
        return WizardPath.TENANT_INSTALLATION_NO_BATTERY;
      case 3:
        return WizardPath.TENANT;
      case 4:
        return WizardPath.STRING_INVERTER;
      default:
        return WizardPath.INSTALLATION;
    }
  }

  get isNoBatteryPath(): boolean {
    const noBatteryPaths = [WizardPath.TENANT_INSTALLATION_NO_BATTERY, WizardPath.TENANT_STRING_INVERTER, WizardPath.STRING_INVERTER];
    return noBatteryPaths.includes(this.wizardState.wizardPath);
  }

  setWizardSettings() {
    const localProject = { ...this.project };
    if (localProject.meta.wizardSettings === undefined) {
      localProject.meta.wizardSettings = {
        consumerOptionsEnabled: false,
        producerOptionsEnabled: false,
        selectedHeatingPumps: [],
        isHybrid: this.isHybrid,
        pvPerInverter: {},
        hybridSettings: {
          useBothBatteryInputs: false,
        },
        wizardPath: this.defineWizardPath(),
        chargeStationSettings: [],
      };
    } else {
      this.wizardState.isHybrid = this.isHybrid;
      this.wizardState.wizardPath = this.defineWizardPath();
      if (this.isNoBatteryPath) {
        this.wizardState.producerOptionsEnabled = false;
        localProject.meta.wizardSettings.producerOptionsEnabled = false;
      }
      localProject.meta.wizardSettings.isHybrid = this.wizardState.isHybrid;
      localProject.meta.wizardSettings.wizardPath = this.wizardState.wizardPath;
      localProject.meta.hasLynusInverter = this.hasLynusInverter(this.wizardState.wizardPath);
    }
    this.updateProject(localProject);
  }

  hasLynusInverter(index: number) {
    const withInverterIndices = [0, 1];
    return withInverterIndices.includes(index);
  }

  loadWizardSettings() {
    this.isHybrid = this.project?.meta.wizardSettings?.isHybrid ?? false;
    if (this.wasInstallationWizardCompleted) {
      this.activeIndex = this.project?.meta.wizardSettings?.wizardPath ?? WizardPath.INSTALLATION;
    } else if (this.wizardState.navigationDirection === NavigationDirection.backward) {
      this.activeIndex = this.wizardState.wizardPath ?? WizardPath.INSTALLATION;
    } else {
      this.activeIndex = this.project?.meta.wizardSettings?.wizardPath ?? -1;
    }

    if (this.activeIndex !== -1) {
      if (this.activeIndex === 5) {
        this.questionnaireItems[3].value = true;
        this.questionnaireItems[4].value = true;
      } else {
        this.questionnaireItems[this.activeIndex].value = true;
      }
    }
  }

  get isOneSelected() {
    return this.questionnaireItems.some((item: any) => item.value);
  }

  get noInverter() {
    return this.activeIndex === 3 || this.activeIndex === 4 || this.activeIndex === 5 || this.activeIndex === 2;
  }

  created() {
    this.loadWizardSettings();
    if (!this.isSolarmax) {
      this.questionnaireItems = [
        ...this.questionnaireItems,
        {
          label: this.$t('installationWizard.generalSettings.questionnairePage.questionnaireItems.lynusSystemNoBatteryWithTenant'),
          value: false,
        },
        {
          label: this.$t('installationWizard.generalSettings.questionnairePage.questionnaireItems.onlyTenant'),
          value: false,
        },
        {
          label: this.$t('installationWizard.generalSettings.questionnairePage.questionnaireItems.onlyStringInverter'),
          value: false,
        },
      ];
    }
  }

  get neededSystemsControllerMappings(): any {
    return {
      grid: {
        ems: gridMappingEMS,
        energyView: gridMappingEnergyView,
      },
    };
  }

  // some paths included grid and a generator port and some dont
  // reset the controller mappings to the default ones available for the path
  resetPathSpecificControllerMappings() {
    let neededSystems: string[] = [];
    let nonAvailableSystems: string[] = [];
    let hasGeneratorPort = true;
    const emsMappings = _.cloneDeep(this.emsDevice.data.meta.controllerMappings);
    const energyViewMappings = _.cloneDeep(this.energyViewDevice.data.meta.controllerMappings);

    switch (this.wizardState.wizardPath) {
      case WizardPath.STRING_INVERTER:
      case WizardPath.TENANT_STRING_INVERTER:
        neededSystems = [];
        hasGeneratorPort = false;
        nonAvailableSystems = ['grid', 'generator', 'chp'];
        break;
      case WizardPath.TENANT_INSTALLATION_NO_BATTERY:
        neededSystems = ['grid'];
        hasGeneratorPort = false;
        nonAvailableSystems = ['generator', 'chp'];
        break;
      default:
        neededSystems = ['grid'];
        nonAvailableSystems = [];
        break;
    }

    neededSystems.forEach((system: any) => {
      if (this.emsDevice.data.meta.controllerMappings[system].count === 0) {
        emsMappings[system].components[`${system}1`] = this.neededSystemsControllerMappings[system].ems;
        emsMappings[system].count = 1;
        energyViewMappings[system].components[`${system}1`] = this.neededSystemsControllerMappings[system].energyView;
        energyViewMappings[system].count = 1;

        this.setEMSSystemCount({ system, count: 1 });
        this.setEnergyViewSystemCount({ system, count: 1 });
      }
    });

    nonAvailableSystems.forEach((system: string) => {
      if (this.emsDevice.data.meta.controllerMappings[system].count !== 0) {
        emsMappings[system].components = {};
        emsMappings[system].count = 0;
        energyViewMappings[system].components = {};
        energyViewMappings[system].count = 0;

        this.setEMSSystemCount({ system, count: 0 });
        this.setEnergyViewSystemCount({ system, count: 0 });
      }
    });

    this.updateEMSDeviceData({ data: { meta: { controllerMappings: emsMappings } } });
    this.updateEnergyViewDeviceData({ data: { meta: { controllerMappings: energyViewMappings } } });

    const savedProducerOptionsEnabled = this.project.meta.wizardSettings?.producerOptionsEnabled ?? false;
    this.setProducerOptionsEnabled(hasGeneratorPort ? savedProducerOptionsEnabled : false);
  }

  onNext() {
    const inverterCount = this.isHybrid ? 2 : 1;
    this.setWizardSettings();

    const isNoBatteryPath = ![WizardPath.INSTALLATION, WizardPath.INSTALLATION_TENANT].includes(this.wizardState.wizardPath);
    this.setInverterCount(isNoBatteryPath ? 0 : inverterCount);

    this.resetPathSpecificControllerMappings();
    this.handleNext();
  }
}
