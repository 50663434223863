import {
  ActionTree, GetterTree, Module, MutationTree,
} from 'vuex';
import { RootState } from '@/store/types';
import { Map } from 'immutable';
import { IVariablesState } from '@/store/modules/measurements/types';
import MeasurementService from '@/services/MeasurementService';

const state: IVariablesState = {
  measurements: Map(),
  mqttMeasurements: Map(),
  measurementsKeys: [],
  measurementsFilter: '',
  measurementsFilterCount: 0,
};

const getters: GetterTree<IVariablesState, RootState> = {
  measurements(state: IVariablesState) {
    return state.measurements.toJS();
  },

  /**
   * Filters measurements by name length
   * @param state
   */
  measurementsKeys(state: IVariablesState) {
    return state.measurements.keySeq().filter(variable => variable).toJS();
  },

  measurementsFilterCount(state: IVariablesState) {
    return state.measurementsFilterCount;
  },
};

const mutations: MutationTree<IVariablesState> = {
  setMeasurements(state: IVariablesState, measurements: Map<string, string | number>) {
    state.measurements = measurements;
  },
  setMqttMeasurements(state: IVariablesState, measurements: Map<string, string | number>) {
    state.mqttMeasurements = measurements;
  },
  setMeasurementsKeys(state: IVariablesState, measurements: any) {
    state.measurementsKeys = Object.keys(measurements);
  },
  setMeasurement(state: IVariablesState, record: any) {
    if (record.vs) {
      state.measurements = state.measurements.set(record.n, record.vs);
    } else {
      state.measurements = state.measurements.set(record.n, record.v);
    }
  },
  setMeasurementsFilter(state: IVariablesState, name: string) {
    state.measurementsFilter = name;
  },
  setMeasurementsFilterCount(state: IVariablesState, count: number) {
    state.measurementsFilterCount = count;
  },
};

const actions: ActionTree<IVariablesState, RootState> = {
  /**
   * Load project measurements
   * @param commit
   * @param projectId
   */
  async fetchMeasurements({ commit }, projectId) {
    try {
      const measurements = await MeasurementService.fetchMeasurements(projectId);
      commit('setMeasurements', Map(measurements));
      commit('setMeasurementsKeys', measurements);
    } catch (e) {
      commit('app/setReport', {
        type: 'error',
        message: e.message,
        value: true,
      }, { root: true });
    }
  },
};

export const measurements: Module<IVariablesState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
